import React, { Fragment, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { GenericPageSection } from './GenericPageSection';
import { GenericPageStoreUpdater } from './GenericPageStoreUpdater';
import { PageMeta } from './PageMeta';
import { SupplementaryLayout } from './SupplementaryLayout';
import { PageType } from '@AuroraTypes';
import { LoadingEmptyScreen } from '@Components/LoadingEmptyScreen/LoadingEmptyScreen';
import { PageNoticeComponent } from '@Components/PageNotice/PageNoticeComponent';
import { SubHeaderLinks } from '@Components/SubHeaderLinks/SubHeaderLinks';
import { usePageLayoutContext } from '@Contexts/PageLayoutContext';
import { TabManagerContextProvider } from '@Contexts/TabManagerContext';
import { ClientError } from '@Core/errors/client/ClientError';
import { ContentNotFoundError } from '@Core/errors/client/ContentNotFoundError';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { scrollToTop } from '@Core/scroll-helpers/scrollToTop';
import { StoreOverride, useGenericPageStore, useStoreContext } from '@Stores/StoreContext';

const CHECKOUT_PAGE_TYPES: PageType[] = [
  'offersummary',
  'passengerdetails',
  'paymentdetails',
  'paymentdetailsbookable',
  'confirmation',
  'confirmationbookable',
  'checkouterror',
];

export function isCheckoutPages(pageType: PageType): boolean {
  return CHECKOUT_PAGE_TYPES.includes(pageType);
}

const GenericPageContent: React.FC = () => {
  const {
    headerConfig: { displaySubHeader },
  } = usePageLayoutContext();
  const { pathname } = useLocation();
  const dynamicPageMeta = useFeatureFlag('DynamicPageMeta');
  const {
    pageContent: {
      meta,
      notices,
      breadcrumbs,
      showBreadcrumbsOnMobile,
      initialSearchSelection,
      supplementaryLayout,
      pageType,
      pageLayout,
    },
    status,
    errorMessage,
  } = useGenericPageStore((state) => state);

  const { genericPage } = useStoreContext();

  useEffect(() => {
    return genericPage.subscribe(
      (state) => state.status,
      (newStatus) => {
        if (newStatus === 'ok') {
          scrollToTop();
        }
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === 'content-not-found') {
    throw new ContentNotFoundError(errorMessage);
  }
  if (status === 'client-error') {
    throw new ClientError(errorMessage);
  }

  return useMemo(
    () => (
      <Fragment>
        {dynamicPageMeta && meta && (
          <PageMeta
            meta={meta}
            pathname={pathname}
          />
        )}

        {displaySubHeader && (
          <React.Fragment>
            <PageNoticeComponent notices={notices} />
            <SubHeaderLinks
              breadcrumbs={breadcrumbs}
              showBreadcrumbsOnMobile={showBreadcrumbsOnMobile}
            />
          </React.Fragment>
        )}

        <StoreOverride
          initialValues={{
            searchSelection: initialSearchSelection,
          }}
        >
          {supplementaryLayout && <SupplementaryLayout {...supplementaryLayout} />}

          <TabManagerContextProvider>
            {status === 'loading' && <LoadingEmptyScreen />}
            {status !== 'loading' && <GenericPageSection />}
          </TabManagerContextProvider>
        </StoreOverride>
      </Fragment>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageType, meta, breadcrumbs, notices, displaySubHeader, supplementaryLayout, pageLayout],
  );
};

const GenericPage = () => {
  return (
    <GenericPageStoreUpdater>
      <GenericPageContent />
    </GenericPageStoreUpdater>
  );
};

export default GenericPage;
