import React, { Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';

import { PageMeta as PageMetaType } from '@AuroraTypes';

/**
 * This looks like a react-helmet-async bug where it adds
 * props with `null`s as values instead of skipping them
 * like React does.
 */
const filterUnwantedValues = (obj: any) =>
  Object.entries(obj).reduce<Record<string, any>>((acc, [key, value]) => {
    if (key !== '__typename' && value) {
      acc[key] = value;
    }

    return acc;
  }, {});

interface PageMetaProps {
  meta: PageMetaType;
  pathname: string;
}

export const PageMeta: React.FC<PageMetaProps> = ({ meta, pathname }) => {
  const { title, links = [], metas = [], schema, url } = meta;

  if (url && url !== pathname) {
    if (url.startsWith('https://') && typeof window !== 'undefined') {
      window.location.href = url;

      return null;
    } else {
      return <Redirect to={url} />;
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(
    () => (
      <Fragment>
        <Helmet>
          <title>{title}</title>
          {links.map((link, i) => (
            <link
              key={i}
              {...filterUnwantedValues(link)}
            />
          ))}
          {metas.map((meta, i) => (
            <meta
              key={i}
              {...filterUnwantedValues(meta)}
            />
          ))}
        </Helmet>

        {schema &&
          [].concat(schema).map((snippet, i) => (
            <script
              key={i}
              type="application/ld+json"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(snippet),
              }}
            />
          ))}
      </Fragment>
    ),
    [title, links, metas, schema],
  );
};
