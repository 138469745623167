import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useClient } from 'urql';

import getGenericPage from './getGenericPage.gql';
import { setPageTypeClient, useAppContext } from '@Contexts/contexts';
import { useQueryParams } from '@Core/helpers/url';
import { readOrFetchQuery } from '@Core/readOrFetchQuery';
import { Route } from '@Server/routes/Route';
import { DEFAULT_PAGE_CONTENT } from '@Stores/GenericPageStore';
import { getSearchSelectionFromUrl } from '@Stores/SearchSelectionStore/getSearchSelectionFromUrl';
import { useStoreContext } from '@Stores/StoreContext';

export const GenericPageStoreUpdater: React.FC = ({ children }) => {
  const { site, routes } = useAppContext();
  const initialised = useRef(false);
  const urqlClient = useClient();
  const { genericPage } = useStoreContext();
  const { pathname, search } = useLocation();
  const { q: searchTerm } = useQueryParams();
  const isMounted = useRef(false);
  const isSSR = typeof window === 'undefined';
  const isHydrated = !isSSR && window.hydrationFinished;
  const route = routes.find((route: Route) => {
    const match = matchPath(pathname, {
      path: route.path,
    });

    if (route.exact) {
      return !!match?.isExact;
    } else {
      return !!match;
    }
  });

  const fetchAndUpdateGenericPage = useCallback(
    ({
      isInitial = false,
      pathname,
      search,
      searchTerm,
    }: {
      isInitial?: boolean;
      pathname: string;
      search: string;
      searchTerm: string;
    }) => {
      if (isInitial) {
        // we don't want to use whatever was before in the store before.
        genericPage.setState({ status: 'loading', pageContent: DEFAULT_PAGE_CONTENT });
      } else {
        genericPage.setState({ status: 'refreshing' });
      }

      const networkOnly = route?.disableCache && isHydrated;

      return readOrFetchQuery(
        urqlClient,
        getGenericPage,
        {
          path: pathname,
          searchSelection: getSearchSelectionFromUrl(search),
          autogenerated: search.includes('autogenerated=true'),
          searchTerm,
        },
        ({ data, error }) => {
          if (error) {
            if (error?.graphQLErrors?.[0]?.extensions?.code === '404') {
              genericPage.setState({ status: 'content-not-found', errorMessage: pathname });
            } else {
              genericPage.setState({ status: 'client-error', errorMessage: error.message });
            }

            return;
          }
          const pageContent = data?.Content.page;

          if (!pageContent?.meta) {
            genericPage.setState({ status: 'content-not-found', errorMessage: pathname });

            return;
          }

          genericPage.setState({ pageContent: pageContent!, status: 'ok' });
          setPageTypeClient(`${site.pathPrefix}${pathname}`, pageContent.pageType);
        },
        {
          ...(networkOnly && {
            requestPolicy: 'network-only',
          }),
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [site],
  );

  // 1. Initialise GenericPage store from URL params
  if (!initialised.current) {
    initialised.current = true;

    const initialFetch = fetchAndUpdateGenericPage({
      pathname,
      search,
      isInitial: true,
      searchTerm,
    });

    // The initial fetch returns a promise in SSR prepass, which we need to throw for Suspense
    if (initialFetch instanceof Promise && typeof window === 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw initialFetch;
    }
  }

  // 2. Update GenericPage store on URL change
  useEffect(
    () => {
      if (isMounted.current) {
        fetchAndUpdateGenericPage({
          pathname,
          search,
          searchTerm,
        });
      }
      isMounted.current = true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname],
  );

  return <Fragment>{children}</Fragment>;
};
