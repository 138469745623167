import { ModalHeader, Selector } from '@loveholidays/design-system';
import React, { Fragment, useCallback, useEffect } from 'react';

import {
  GenericLayoutComponent,
  SupplementaryLayout as SupplementaryLayoutType,
} from '@AuroraTypes';
import { Container } from '@Components/Container/Container';
import { LazyComponent, preloadComponent } from '@Components/LayoutComponents/LazyComponent';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';

const LinearLayout: React.FC<{ components: GenericLayoutComponent[] }> = ({ components }) => (
  <Fragment>
    {components.map(({ type, container, ...props }, index) => (
      <Container
        key={container?.id || index}
        id={container?.id}
        contentWidth={container?.contentWidth || 'default'}
        spacingY={container?.spacingY || 'default'}
      >
        <LazyComponent
          index={index}
          type={type}
          id=""
          {...props}
        />
      </Container>
    ))}
  </Fragment>
);

interface SupplementaryLayoutProps
  extends Pick<SupplementaryLayoutType, 'fabButtonText' | 'modalTitle' | 'layout'> {}

export const SupplementaryLayout: React.FC<SupplementaryLayoutProps> = ({
  fabButtonText,
  modalTitle,
  layout,
}) => {
  const [isOpen, setOpen, setClosed] = useModal();

  const handleModalClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    function handleModalClick(event) {
      if (event.target instanceof HTMLAnchorElement && event.target.href) {
        setClosed();
      }
    },
    [setClosed],
  );

  useEffect(() => {
    const supportsRequestIdleCallback =
      typeof window.requestIdleCallback !== 'undefined' &&
      typeof window.cancelIdleCallback !== 'undefined';
    function load() {
      for (const component of layout) {
        preloadComponent(component);
      }
    }

    if (supportsRequestIdleCallback) {
      const handle = window.requestIdleCallback(load);

      return () => {
        window.cancelIdleCallback(handle);
      };
    } else {
      // Safari does not yet support requestIdleCallback
      // https://caniuse.com/requestidlecallback
      const handle = setTimeout(load, 1000);

      return () => {
        clearTimeout(handle);
      };
    }
  }, [layout]);

  return (
    <Fragment>
      <Selector
        data-id="supplementary-layout-fab"
        isSelected
        icon="Navigation/Read"
        variant="Heavy"
        size="48"
        label={fabButtonText}
        sx={{
          position: 'fixed',
          bottom: 40,
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'max-content',
          boxShadow: 'elevationFloating',
          zIndex: 'filterOpener',
        }}
        onClick={setOpen}
      />
      {isOpen && (
        <LoadableModal
          onClose={setClosed}
          show
          Header={
            <ModalHeader
              label={modalTitle}
              onClose={setClosed}
              layout="medium"
            />
          }
          Content={
            <div onClick={handleModalClick}>
              <LinearLayout components={layout} />
            </div>
          }
        />
      )}
    </Fragment>
  );
};
